<template>
  <div class="loginBox" id="loginBox">
    <a-row class="login">
      <a-col class="left" :xl="14" :lg="14">
        <img class="leftPic" src="@/assets/images/login/loginPic.png" alt="" />
      </a-col>
      <a-col class="right" :xl="10" :lg="10">
        <div class="rightFix">
          <a-select ref="select" v-model:value="selectValue" style="width: 164px" :placeholder="t('languMsg')" @change="handleChange" size="large">
            <a-select-option value="zh_CN">Chinese 中文</a-select-option>
            <a-select-option value="en_US">English 英语</a-select-option>
          </a-select>
        </div>
        <div class="contBox">
          <div class="navBox">
            <img class="logoImg" src="@/assets/images/amazon_and_wahool.png" alt="" />
          </div>
          <div class="authorized-title">{{ t('login.amazonLoginTitle') }}</div>
          <div class="formBox">
            <a-form ref="ruleForm" :model="form" :rules="rules">
              <a-space direction="vertical" :size="24" style="width: 100%">
                <a-form-item name="name">
                  <a-input
                    class="lg-form-input"
                    v-model:value="form.name"
                    :placeholder="t('login.userNamePla')"
                    @pressEnter="submin"
                    style="padding-left: 38px; border-radius: 10px; position: relative; height: 40px"
                  ></a-input>
                  <div class="formItemName">
                    {{ t('login.usernameLabel') }}
                  </div>
                  <UserOutlined class="inputIcon" style="color: rgba(0, 0, 0, 0.45); font-size: 18px; margin-right: 6px" type="user" />
                </a-form-item>
                <a-form-item name="password" style="margin-top: 20px">
                  <a-input
                    class="lg-form-input"
                    :type="inputType ? 'password' : 'text'"
                    style="padding-left: 38px; border-radius: 10px; position: relative; height: 40px"
                    v-model:value="form.password"
                    :placeholder="t('login.passwordPla')"
                  ></a-input>
                  <div class="formItemName">
                    {{ t('login.passwordLabel') }}
                  </div>
                  <KeyOutlined class="inputIcon" style="" />
                  <div class="eyeBox">
                    <EyeInvisibleOutlined v-if="inputType" @click="typeClick" />
                    <EyeOutlined v-else @click="typeClick" />
                  </div>
                </a-form-item>
              </a-space>
            </a-form>
            <a-button class="subBtn" :loading="loading" type="primary" block @click="submin">
              {{ t('login.amazonLogin') }}
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal v-model:visible="visible" :title="t('login.selectStoreTitle')" :footer="() => null">
      <div class="modal-select-list">
        <a-radio-group v-model:value="checkboxValue" @change="checkboxOnChange">
          <div class="modal-select-li" :span="8" v-for="item in storeInfoRef.stores" :key="item.idCode">
            <!-- 已授权的：disabled   authonActive-->
            <a-radio :style="radioStyle" :value="item.idCode">{{ item.name }}</a-radio>
            <span>{{ item.authorizationStateDesc ? `( ${item.authorizationStateDesc} )` : '' }}</span>
          </div>
        </a-radio-group>
      </div>
      <!-- <p class="modal-select-num">{{ t('login.selectedText') }}: {{ checkboxValue.length }} {{ t('login.selectedNum') }}</p> -->
      <div class="modal-footer">
        <div class="modal-cancel-btn" @click="handleCancel">{{ t('login.modalCancel') }}</div>
        <div class="modal-confirm-btn" :class="{ 'disable-btn': !checkboxValue }" @click="handleConfirm">{{ t('login.modalConfirm') }}</div>
      </div>
    </a-modal>
    <a-modal v-model:visible="errVisible" :closable="false" :footer="null">
      <div class="contentBox">
        <div class="title">{{ t('Amazon.authorizationFailedErr') }}！</div>
        <div class="desc">
          {{ t('Amazon.authorizationBinding') }}
          <span>{{ storeInfoRef.othersAuthorizedBusinessName }} - {{ storeInfoRef.othersAuthorizedStoreName }}</span>
          ，{{ t('Amazon.authorizationRetry') }}
        </div>
        <div class="btn" @click="closeErr">{{ t('Amazon.closeBtn') }}</div>
      </div>
    </a-modal>
  </div>
</template>
<script>
  import { UserOutlined, KeyOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons-vue'
  import { ref, defineComponent, onMounted, reactive, watch } from 'vue'
  import { storesForAmazonList } from '@/api/live.js'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'LoginIndex',
    components: { UserOutlined, KeyOutlined, EyeInvisibleOutlined, EyeOutlined },
    setup() {
      const { t, locale } = useI18n()
      const route = useRoute()
      const rules = ref()
      const inputType = ref(true)
      const selectValue = ref(null)
      const router = useRouter()
      const store = useStore()
      let loading = ref(false)
      let rememberMeFlag = ref(false)
      const ruleForm = ref()
      const form = reactive({
        name: '',
        password: ''
      })
      const radioStyle = reactive({
        display: 'flex'
      })
      const errVisible = ref(false) //授权失败的时候展示
      // 自己的店铺列表
      const storeInfoRef = ref({})
      // 选择店铺弹窗
      const visible = ref(false)
      // 自己的店铺列表
      const storeList = ref([])
      // 要跳转的亚马逊链接
      const hrefUrl = ref('')
      // 单选框选中的值
      const checkboxValue = ref(JSON.parse(sessionStorage.getItem('storeIdCodes')) || '')
      //判断语言并赋值
      const payload = localStorage.getItem('languageSet') || 'zh_CN'
      if (payload) {
        selectValue.value = payload
      }
      //根据语言变化重新赋值rules
      const rulesFun = () => {
        rules.value = {
          name: {
            required: true,
            message: t('login.userNamePlas'),
            trigger: 'blur'
          },
          password: {
            required: true,
            message: t('login.passwordPla'),
            trigger: 'blur'
          }
        }
        console.log(rules.value, 'rules.value--- rules.value')
      }
      rulesFun()

      //切换语言
      const handleChange = el => {
        console.log(el, 'el-el')
        locale.value = el
        localStorage.setItem('languageSet', el)
        rulesFun()
      }
      //登录
      const submin = () => {
        ruleForm.value
          .validate()
          .then(() => {
            loading = true
            const loginData = {
              account: form.name,
              password: form.password
            }
            store
              .dispatch('login', loginData)
              .then(async userInfo => {
                // 请求商店列表
                const payload = getUrlParams(location.href)
                const res = await storesForAmazonList({ sellingPartnerId: payload.sellingPartnerIds })
                console.log(res, 'result---result')
                if (res.code === 200) {
                  if (res.data.sellerCentralOthersAuthorized) {
                    errVisible.value = true
                  } else {
                    visible.value = true
                  }
                  storeInfoRef.value = res.data
                }
                hrefUrl.value = `${sessionStorage.getItem('amazon_callback_uri')}?redirect_uri=https://${
                  window.location.hostname
                }/integrated/Settings&amazon_state=${sessionStorage.getItem('amazon_state')}&state=${userInfo?.idCode}`
              })
              .catch(() => {
                loading = false
              })
          })
          .catch(() => {})
      }
      const getUrlParams = url => {
        // 通过 ? 分割获取后面的参数字符串
        let urlStr = url.split('?')[1]
        // 创建空对象存储参数
        let obj = {}
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
          // 再通过 = 将每一个参数分割为 key:value 的形式
          let arr = paramsArr[i].split('=')
          obj[arr[0]] = arr[1]
        }
        return obj
      }
      async function setRouterRedirect(userType) {
        await store.dispatch('setUserType', userType)
        store.dispatch('routerRedirect')
      }
      //监听语言切换，提示信息语言实时响应切换
      watch(locale, () => {
        rulesFun()
      })

      function rememberMe() {
        rememberMeFlag.value = !rememberMeFlag.value
        window.localStorage.setItem('rememberMe', rememberMeFlag.value)
      }

      onMounted(() => {
        if (window.localStorage.getItem('rememberMe') == 'true') {
          rememberMeFlag.value = window.localStorage.getItem('rememberMe') == 'true' ? true : false
        }
      })
      const typeClick = () => {
        inputType.value = !inputType.value
      }
      /**
       * 多选框选中事件
       */
      const checkboxOnChange = val => {
        sessionStorage.setItem('storeIdCodes', JSON.stringify(val.target.value))
      }
      /**
       * 点击弹窗确定跳转亚马逊
       */
      const handleConfirm = () => {
        if (checkboxValue.value) {
          location.href = hrefUrl.value
        }
      }
      /**
       * 点击弹窗取消
       */
      const handleCancel = () => {
        location.reload()
      }
      return {
        t,
        inputType,
        typeClick,
        selectValue,
        ruleForm,
        form,
        rules,
        submin,
        loading,
        rememberMeFlag,
        rememberMe,
        handleChange,
        visible,
        storeList,
        hrefUrl,
        checkboxValue,
        checkboxOnChange,
        handleConfirm,
        handleCancel,
        radioStyle,
        storeInfoRef
      }
    }
  })
</script>
<style lang="less" scoped>
  ::v-deep(.ant-radio-wrapper) {
    display: flex;
    align-items: center;
  }
  ::v-deep(.ant-radio-group) {
    width: 100%;
  }
  ::v-deep(.ant-radio) {
    width: 16px;
    height: 16px;
  }
  .contentBox {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    .title {
      font-size: 20px;
      color: #fb5d43;
      font-weight: 600;
      text-align: center;
    }
    .desc {
      font-size: 15px;
      color: #797979;
      font-weight: 400;
      margin: 52px 0;
      text-align: center;
      span {
        font-size: 15px;
        color: #ff6700;
        font-weight: 400;
      }
    }
    .btn {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #000;
      text-align: center;
      background: #fff;
      font-size: 15px;
      line-height: 40px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .authonActive {
    color: rgba(0, 0, 0, 0.25);
  }
  .inputIcon {
    color: rgba(0, 0, 0, 0.45) !important;
    font-size: 18px;
    margin-right: 6px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .eyeBox {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 14px;
  }
  * {
    margin: 0;
    padding: 0;
  }
  .loginBox {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .login {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .left {
        width: 58%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #faf9fe;
        .leftPic {
          width: 500px;
          height: 500px;
          display: flex;
        }
      }
    }
    .right {
      width: 42%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      position: relative;
    }
  }
  .contBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    .navBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 36px;
      .logoImg {
        width: 329px;
        height: 48px;
        margin-right: 12px;
      }
      span {
        font-size: 20px;
        line-height: 48px;
        color: #131011;
      }
    }
    .formBox {
      width: 330px;
      .lg-form-input {
        width: 100%;
        height: 40px;
        padding: 0px 10px;
        border-radius: 8px;
        border: 1px solid #d2d2d2;
        overflow: hidden;
        box-sizing: border-box;
        font-size: 14px;
        margin: 0;
      }
    }
  }
  .formItemName {
    position: absolute;
    top: -22px;
    left: 2px;
    font-size: 12px;
    line-height: 18px;
    color: #131011;
    margin-bottom: 4px;
  }
  .subBtn {
    width: 330px;
    height: 40px;
    background: #131011;
    border-radius: 8px;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    border: none;
  }
  .rightFix {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  #login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    padding: 0 10px;

    #bj-content {
      height: 500px;
      //background: white;
      border-radius: 7px;
      overflow: hidden;

      #lg-right,
      #lg-left {
        width: 450px;
        height: 100%;
        position: relative;
      }

      #lg-right {
        padding: 48px;
        font-weight: 400;
        color: #858796;

        h1 {
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 24px;
          color: #3a3b45 !important;
          margin: 0;
          padding: 0;
          position: relative;
          span {
            font-size: 18px;
            margin-left: 5px;
          }
        }

        #lg-form {
          width: 100%;
          margin-top: 20px;
          //border-bottom: 1px solid rgba(0, 0, 0, .1);
          padding-bottom: 20px;
          position: relative;

          .lg-form-input {
            width: 100%;
            height: 40px;
            padding: 0px 10px;
            border-radius: 8px;
            border: 1px solid #d2d2d2;
            overflow: hidden;
            box-sizing: border-box;
            font-size: 14px;
            margin: 0;
          }

          .lg-control-label {
            height: 50px;
            display: inline-block;
            line-height: 50px;
            position: relative;
            padding-left: 26px;
            font-size: 14px;
            color: #858796;
          }

          .lg-control-label::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: block;
            width: 16px;
            height: 16px;
            pointer-events: none;
            content: '';
            background-color: #fff;
            border: #b7b9cc solid 1px;
            border-radius: 4px;
            transition: all 0.3s;
          }

          .lg-control-label:hover::before {
            box-shadow: 0px 0px 5px #4e73df;
            transition: box-shadow 0.3s;
          }

          .lg-control-label-s::before {
            border-color: #4e73df;
            background-color: #4e73df;
          }

          .lg-control-label-s::after {
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
            display: block;
            width: 16px;
            height: 16px;
            content: '';
            border-color: #4e73df;
            background-color: #4e73df;
            background: 50%/50% 50% no-repeat;
            background-image: url('~@/assets/images/login/lg-1.svg');
          }

          .lg-form-button {
            color: #fff;
            background-color: #1890ff;
            border-color: #1890ff;
            width: 100%;
            height: 45px;
            border-radius: 24px;
            margin-top: 30px;
          }
        }
      }

      #lg-left {
        background: url('~@/assets/images/login/login-bj.svg');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    #login-content {
      width: 500px;
      min-height: 0px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      padding: 30px 30px 10px;
      position: relative;

      .login-input-title {
        font-size: 24px;
        color: white;
        float: left;
        position: absolute;
        top: -80px;
        left: 0;
      }
    }

    .login-button {
      border-radius: 5px;
      height: 40px;
    }
  }
  .authorized-title {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 42px;
  }
  :global(.ant-modal) {
    top: 220px !important;
    width: 478px !important;
  }
  :global(.ant-modal-header) {
    border-bottom: none;
    text-align: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  :global(.ant-modal-content) {
    padding: 0 32px;
  }
  .modal-select-num {
    text-align: center;
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    margin: 25px 0;
  }
  .modal-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .modal-cancel-btn {
      display: flex;
      width: 133px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid #000;
      background: #fff;
      cursor: pointer;
    }
    .modal-confirm-btn {
      display: flex;
      width: 133px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      background: var(--grayscale-900, #131011);
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }
    .disable-btn {
      background: #ccc !important;
    }
  }
  .modal-select-list {
    overflow-y: scroll;
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  .modal-select-li {
    display: flex;
    width: 95%;
    min-height: 45px;
    // justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: -2px 0px 10px 0px rgba(170, 170, 170, 0.3);
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    margin: 17px auto;
    padding: 0 10px;
  }
  :global(.ant-checkbox-group) {
    width: 100%;
  }
  .wahool-icon {
    width: 22px;
    height: 22px;
  }
</style>
<style lang="less">
  .ant-form-item-explain-error {
    padding-left: 15px !important;
    margin-top: 5px !important;
  }
</style>
